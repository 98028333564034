<template>
  <YLayoutBodyMenu>
    <section>
      <div class="flex-def flex-zBetween flex-cCenter">
        <div class="section-title">数据概览</div>
        <div class="flex-def tab-box">
          <div @click="tabChange(key)" :class="{active:curTab === key}" class="tab-item" v-for="(item,key) in tabList"
               :key="key">{{ item.text }}
          </div>
        </div>
      </div>
      <el-row v-loading="loading" :gutter="40">
        <el-col :span="6">
          <dashboard-item type="success" icon="member-get">
            {{ card_desc.member_get }}
            <template slot="title">{{ showTabLabel }}收益(元)</template>
            <template slot="tips">免单会员+激活卡订单收益</template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item type="warning" icon="order-num">
            {{ card_desc.order.total }}
            <template slot="title">{{ showTabLabel }}订单数量(单)</template>
            <template slot="tips">
              <div class="flex-def">
                <div class="flex-def" style="width: 60%;white-space: nowrap">
                  免单会员订单：
                  <span style="font-size: 20px;color: #008100">{{ card_desc.order.member }}</span>
                </div>
                <div class="flex-def" style="width: 40%;white-space: nowrap">
                  激活卡：
                  <span style="font-size: 20px;color: #ee8400">{{ card_desc.order.activation }}</span>
                </div>
              </div>
            </template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item type="warning" icon="order-num">
            <template slot="title">{{ showTabLabel }}激活码</template>
            <template slot="tips">
              <div class="flex-def">
                <div class="flex-def" style="width: 60%;white-space: nowrap">
                  激活码订单数量：
                  <span style="font-size: 20px;color: #008100">{{ card_desc.cdkey_order.num }}</span>
                </div>
                <div class="flex-def" style="width: 40%;white-space: nowrap">
                  金额：
                  <span style="font-size: 20px;color: #ee8400">{{ card_desc.cdkey_order.total }}</span>
                </div>
              </div>
            </template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item type="primary" icon="member-pay">
            {{ card_desc.member_pay }}
            <template slot="title">{{ showTabLabel }}免单会员奖励(元)</template>
            <template slot="tips">免单会员订单奖励(包括全返奖励)</template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item type="error" icon="activation-card-pay">
            {{ card_desc.activation_pay }}
            <template slot="title">{{ showTabLabel }}激活卡奖励(元)</template>
            <template slot="tips">激活卡订单奖励</template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item type="success" icon="merchant-num">
            {{ card_desc.merchant_num }}
            <template slot="title">{{ showTabLabel }}新增商家(个)</template>
            <template slot="tips">成功入驻商家+待审商家</template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item type="warning" icon="user-new-num">
            {{ card_desc.user_new_num.total }}
            <template slot="title">{{ showTabLabel }}新增用户(人)</template>
            <template slot="tips">
              <div class="flex-def">
                <div style="width: 50%">
                  平台全部用户
                </div>
                <div class="flex-def" style="width: 50%">
                  免单会员：
                  <span style="font-size: 20px;color: #ee8400">{{ card_desc.user_new_num.member }}</span>
                  人
                </div>
              </div>
            </template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item type="primary" icon="visitor-num">
            {{ card_desc.visitor_num }}
            <template slot="title">{{ showTabLabel }}到店人数(人)</template>
            <template slot="tips">全部商家成功核销卡券数量</template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item type="error" icon="wholesaler-num">
            {{ card_desc.wholesaler_num }}
            <template slot="title">{{ showTabLabel }}新增批发商(人)</template>
            <template slot="tips">激活卡批发商数量</template>
          </dashboard-item>
        </el-col>
      </el-row>
      <div class="section-title" style="margin-top: 50px">待处理业务</div>
      <el-row :gutter="40">
        <el-col :span="6">
          <dashboard-item @click="$router.push({
          path:'/user/finance/wait'
          })" type="error" icon="cash-wait">
            {{ wait.cash }}
            <template slot="title">待提现(个)</template>
            <template slot="tips">已提交提现申请还未打款</template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item @click="$router.push({
          path:'/card/merchant/stop'
          })" type="error" icon="merchant-wait-judge">
            {{ wait.merchant }}
            <template slot="title">待审核商家(个)</template>
            <template slot="tips">未正式上线的商家</template>
          </dashboard-item>
        </el-col>
        <el-col :span="6">
          <dashboard-item @click="$router.push({
          path:'/shop/order/manage'
          })" type="error" icon="order-wait-send">
            {{ wait.order }}
            <template slot="title">待发货订单(个)</template>
            <template slot="tips">成功下单未发货订单</template>
          </dashboard-item>
        </el-col>
      </el-row>
    </section>
  </YLayoutBodyMenu>
</template>

<script>


import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
import DashboardItem from "@/view/card/dashboardItem";

const cardDesc = {
  cdkey_order: {
    num: 0,
    total: 0,
  },
  member_get: 0,//免单会员+激活卡订单收益
  order: {
    total: 0, //免单卡订单总数
    member: 0, //免单会员订单
    activation: 0, //激活卡订单
  },
  member_pay: 0,//免单会员订单奖励(包括全返奖励)
  activation_pay: 0,//激活卡订单奖励
  merchant_num: 0,//成功入驻商家+待审商家
  user_new_num: {
    total: 0, //平台全部用户
    member: 0, //免单会员
  },
  visitor_num: 0,
  wholesaler_num: 0,
}
export default {
  name: "dashboard",
  components: {DashboardItem, YLayoutBodyMenu},
  data() {
    return {
      list: [],
      loading: false,

      curTab: 0,
      tabList: [
        {
          value: 1,
          text: "今日"
        },
        {
          value: 2,
          text: "昨日"
        },
        {
          value: 3,
          text: "本月"
        },
        {
          value: 4,
          text: "全部"
        },
      ],
      card_desc: {...cardDesc},
      wait: {
        cash: 0,
        merchant: 0,
        order: 0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$u.api.card.dashboardV2({
        target: this.tabList[this.curTab].value,
      }).then(res => {
        this.loading = false;
        this.card_desc = res.card_desc;
        this.wait = res.wait;
      })
    },

    tabChange(key) {
      this.curTab = key;
      this.card_desc = {...cardDesc};
      this.load();
    },
  },
  computed: {
    showTabLabel() {
      return this.tabList[this.curTab].text;
    }
  }
}
</script>

<style scoped>
.section-title {
  font-size: 20px;
  font-weight: 600;
}

.tab-box {
  font-size: 20px;
  font-weight: 600;
  color: #6c6c6c;
  overflow: hidden;
  border: 1px solid #f9244c;
  border-radius: 10px;
  line-height: 45px;
}

.tab-item {
  width: 90px;
  text-align: center;
  cursor: pointer;
}

.tab-item.active {
  background-color: #f9244c;
  color: #FFFFFF;
}

.tab-item + .tab-item {
  border-left: 1px solid #f9244c;
}
</style>